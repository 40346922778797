import UI_Typography from '@/components/ui-kit/typography/UI_Typography'
import {useTranslate} from '@/hooks/useTranslate'
import * as Lib from './lib'
import Link from 'next/link'

const GlobalErrorHandler = ({
  statusCode = '429',
  messages,
}: {
  statusCode?: string
  messages?: string
}) => {
  const translate = messages
    ? (key: string) => {
        return messages[key as keyof typeof messages]
      }
    : useTranslate

  const handleGetErrorTitle = (statusCode: string) => {
    switch (statusCode) {
      case '429':
        return translate('429_ERROR_MESSAGE') as string
      default:
        return translate('UNEXPECTED_ERROR_MESSAGE') as string
    }
  }

  const handleGetErrorDescription = (statusCode: string) => {
    switch (statusCode) {
      case '429':
        return translate('429_ERROR_MESSAGE_LINE_TWO') as string
      default:
        return translate('UNEXPECTED_ERROR_MESSAGE_LINE_TWO') as string
    }
  }

  return (
    <div className={'wrapper'}>
      <div className={'cloud'}>
        <Lib.I.Cloud />
      </div>
      <div className={'message'}>
        <UI_Typography component='p' variant='Medium/Med18' color='#fff'>
          {handleGetErrorTitle(statusCode)}
        </UI_Typography>
        <UI_Typography component='p' variant='Medium/Med18' color='#fff'>
          {handleGetErrorDescription(statusCode)}
        </UI_Typography>
      </div>
      <div className={'error_code'}>
        {statusCode === '429' && <UI_Typography variant='Medium/Med53'>{statusCode}</UI_Typography>}

        <div className={'redirect_button'}>
          <Link href='/'>
            <button>
              <UI_Typography variant='Medium/Med18'>
                {translate('CONTINUE_SHOPPING') as string}
              </UI_Typography>
            </button>
          </Link>
        </div>
        <div className={'cloud_big_left'}>
          <Lib.I.Cloud width='150' height='28' />
        </div>
        <div className={'cloud_tiny_left'}>
          <Lib.I.Cloud width='72' height='14' />
        </div>
        <div className={'cloud_big_right'}>
          <Lib.I.Cloud width='132' height='26' />
        </div>
        <div className={'cloud_tiny_right'}>
          <Lib.I.Cloud width='84' height='18' />
        </div>
      </div>

      <section className={'section'}>
        <div className={'big_cloud'}>
          <Lib.I.BigCloud />
          <div className={'wires'}>
            <Lib.I.Wires />
          </div>

          <div className={'left_single_wire'}>
            <Lib.I.LeftSingleWire />
          </div>
          <div className={'right_single_wire'}>
            <Lib.I.RightSingleWire />
          </div>
        </div>

        <div className={'rock_left'}>
          <Lib.I.LeftDesktopRock />
        </div>
        <div className={'rock_right'}>
          <Lib.I.RightDesktopRock />
        </div>
      </section>
    </div>
  )
}
export default GlobalErrorHandler
