'use client'

import GlobalErrorHandler from '@/containers/global-error-handler'
import {defaultLocale, getLanguageCodeFromLocale} from '@/i18n'
import {getClientSideCookie} from '@/utils'
import * as Sentry from '@sentry/nextjs'
import {useEffect, useState} from 'react'

export default function GlobalError({error}: {error: Error}) {
  const language = getClientSideCookie('language') ?? getLanguageCodeFromLocale(defaultLocale)
  const [messages, setMessages] = useState<any>({})

  useEffect(() => {
    async function getMessages() {
      const messages = (await import(`../i18n/messages/${language}.json`)).default

      setMessages(messages)
    }

    getMessages()

    Sentry.captureException(error)
  }, [])

  return (
    <html>
      <title>{messages['NOT_FOUND']}</title>
      <body>
        <GlobalErrorHandler messages={messages} />
      </body>
    </html>
  )
}
